import Footer from "../page/Footer";
import Navbar from "../page/Navbar";
import "./Plan.css";
import { useEffect, useState } from "react";
// import { Helmet } from "react-helmet";
export default function Plan() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [isScrolled, setIsScrolled] = useState(false);
  const handleScroll = () => {
    if (window.scrollY > 60) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const [isScrolled2, setIsScrolled2] = useState(false);
  const handleScroll2 = () => {
    if (window.scrollY > 510) {
      setIsScrolled2(true);
    } else {
      setIsScrolled2(false);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll2);
    return () => {
      window.removeEventListener("scroll", handleScroll2);
    };
  }, []);
  const [isScrolled3, setIsScrolled3] = useState(false);
  const handleScroll3 = () => {
    if (window.scrollY > 970) {
      setIsScrolled3(true);
    } else {
      setIsScrolled3(false);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll3);
    return () => {
      window.removeEventListener("scroll", handleScroll3);
    };
  }, []);
  const [isScrolled4, setIsScrolled4] = useState(false);
  const handleScroll4 = () => {
    if (window.scrollY >= 1450) {
      setIsScrolled4(true);
    } else {
      setIsScrolled4(false);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll4);
    return () => {
      window.removeEventListener("scroll", handleScroll4);
    };
  });
  return (
    <>
      <Navbar />
      <div className="Task_Contant">
        <h1 className="Task_Contant_Title">OotyGo Trip Plan</h1>
        <div className="Task_Item">
          <div className="Task_Items">
            <div className="Plan-1">
              <h3 className="Plan_1_Title">
                Day 1: Arrival and Local Sightseeing
              </h3>
              <video
                src="https://videos.pexels.com/video-files/4254119/4254119-uhd_2732_1440_24fps.mp4"
                className={` ${isScrolled ? "Plan_1_Video" : "visible-video"}`}
                autoPlay
                loop
                muted
              ></video>
              <div className={isScrolled ? "Task_table" : "Task_table_scroll"}>
                <li>
                  Reach Ooty via Coimbatore (90 km) | Mettupalayam (50 km) | via
                  Mysuru - Ooty Rd.
                </li>

                <li>Check-in to your hotel and freshen up.</li>
                <li>
                  Visit:
                  <p>- Botanical Garden (10 am - 5 pm)</p>
                  <p>- Ooty Lake (10 am - 6 pm)</p>
                  <p>- take a boat ride</p>
                  <p>- Thread Garden (10 am - 5 pm)</p>
                  <p>- Karnataka Park (10 am - 5 pm)</p>
                </li>
                <li>Enjoy shopping at Commercial Road</li>
              </div>
            </div>
            <div className="Plan-1">
              <h3 className="Plan_1_Title">Day 2: Ooty Sightseeing</h3>
              <video
                src="https://videos.pexels.com/video-files/28322182/12360728_2560_1440_24fps.mp4"
                className={` ${isScrolled2 ? "Plan_1_Video" : "visible-video"}`}
                autoPlay
                loop
                muted
              ></video>
              <div className={isScrolled2 ? "Task_table" : "Task_table_scroll"}>
                <li>
                  Doddabetta Peak (9 am - 5 pm)
                  <p>- highest point in Ooty</p>{" "}
                </li>
                <li>
                  Tea Museum (10 am - 5 pm)
                  <p>- learn about tea production</p>{" "}
                </li>
                <li>
                  Rose Garden (10 am - 5 pm)
                  <p>- stunning views</p>{" "}
                </li>
                <li>
                  Government Museum (10 am - 5 pm)
                  <p>- insight into Ooty's history</p>
                </li>
                <li>Evening walk at Charring Cross</li>
              </div>
            </div>
            <div className="Plan-1">
              <h3 className="Plan_1_Title">Day 3: Excursions</h3>
              <video
                src="https://videos.pexels.com/video-files/28633939/12436883_640_360_60fps.mp4"
                className={` ${isScrolled3 ? "Plan_1_Video" : "visible-video"}`}
                autoPlay
                loop
                muted
              ></video>
              <div className={isScrolled3 ? "Task_table" : "Task_table_scroll"}>
                <li>
                  Sim's Park (9 am - 5 pm)
                  <p>- beautiful gardens</p>{" "}
                </li>
                <li>
                  Dolphin's Nose (9 am - 5 pm)
                  <p>- stunning views</p>{" "}
                </li>
                <li>
                  Lamb's Rock (9 am - 5 pm)
                  <p>- panoramic views</p>
                </li>
                <li>
                  Government Museum (10 am - 5 pm)
                  <p>- insight into Ooty's history</p>
                </li>
                <li>Return to Ooty by evening</li>
              </div>
            </div>
            <div className="Plan-1">
              <h3 className="Plan_1_Title">Optional (Day 4-5)</h3>
              <video
                src="https://videos.pexels.com/video-files/6821235/6821235-uhd_2560_1440_30fps.mp4"
                className={` ${isScrolled4 ? "Plan_1_Video" : "visible-video"}`}
                autoPlay
                loop
                muted
              ></video>
              <div className={isScrolled4 ? "Task_table" : "Task_table_scroll"}>
                <li>
                  Mudumalai National Park (35 km, 2 hours):
                  <p>- Boat ride and fishing</p>
                  <p>- Waterfalls hike</p>
                </li>
                <li>
                  Pykara Lake and Waterfalls (25 km, 1.5 hours):
                  <p>- Wildlife safari</p>
                  <p> - Elephant camp visit</p>
                </li>
                <li>
                  Avalanche Lake (28 km, 2 hours):
                  <p>- Trout fishing</p>
                  <p> - Scenic views</p>
                </li>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
