import Navbar from "../page/Navbar";
import "./Posto.css";
import AWS from "aws-sdk";
import { useState, useEffect } from "react";
import { MdVideoChat } from "react-icons/md";
import { FaRegHeart } from "react-icons/fa";
import { CgComment } from "react-icons/cg";
import { FaChevronCircleUp } from "react-icons/fa";
import { FaChevronCircleDown } from "react-icons/fa";
import { IoIosSend } from "react-icons/io";
// import { uploadData } from "aws-amplify/storage";

export default function Posto() {
  // Initialize AWS SDK
  AWS.config.update({
    region: process.env.REACT_APP_AWS_REGION,
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  });
  const [data, setData] = useState([]);
  useEffect(() => {
    const dynamodb = new AWS.DynamoDB.DocumentClient();
    const params = {
      TableName: "Ootygo-posto-videos",
    };

    dynamodb.scan(params, (err, result) => {
      if (err) {
        console.error("Error fetching data from DynamoDB:", err);
      } else {
        setData(result.Items);
      }
    });
  }, []);
  // send data to Posto

  const docClient = new AWS.DynamoDB.DocumentClient();

  const [text, setText] = useState("");

  const handleSubmit = (event, id, name, comment) => {
    event.preventDefault();
    // Ensure `comment` is an array of objects
    if (!Array.isArray(comment)) {
        console.error("Invalid comment structure:", comment);
        return;
    }
    const updatedComments = [...comment, { S: text }];
    const params = {
      TableName: "Ootygo-posto-videos",
      Item: {
        // Assuming your table has a primary key named 'id' and a text attribute 'text'.
        id: id,
        name: name,
        comment: {
          L: updatedComments,
        },
      },
    };

    docClient.put(params, (err, data) => {
      if (err) {
        console.error(
          "Unable to add item. Error JSON:",
          JSON.stringify(err, null, 2)
        );
      } else {
        console.log("Added item:", JSON.stringify(data, null, 2));
        setText(""); // Clear the input field after successful submission
      }
    });
  };

  return (
    <>
      <Navbar />
      <div className="posto_contant">
        <h1 className="posto_contant_title">
          <MdVideoChat /> Posto
        </h1>
        <div className="posto_contant_item">
          <div className="posto_contant_Video">
            {data.map(({ id, name, comment, like, link }) => {
              return (
                <div className="posto_contant_items" key={id}>
                  <div className="posto_video">
                    <video src={link}></video>
                    <h3>{name}</h3>
                    <div>
                      <span>
                        <FaRegHeart />
                        {like}
                      </span>
                      <span>
                        <CgComment />
                      </span>
                    </div>
                  </div>
                  <div className="posto_comment_sec">
                    {Array.isArray(comment) ? (
                      comment.map((c, idx) => (
                        <li key={idx} className="posto_comments">
                          {c.S}
                        </li>
                      ))
                    ) : (
                      <span>No comments yet.</span>
                    )}
                  </div>
                  <div>
                    <form
                      className="posto_comments_send_sec"
                      onSubmit={(e) => handleSubmit(e, id, name, comment)}
                    >
                      <input
                        type="text"
                        placeholder="Type comments..."
                        className="posto_comments_send"
                        value={text}
                        onChange={(e) => setText(e.target.value)}
                      />
                      <button>
                        <IoIosSend />
                      </button>
                    </form>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="posto_move_btns">
            <button className="posto_move">
              <FaChevronCircleUp />
            </button>
            <br />
            <button className="posto_move">
              <FaChevronCircleDown />
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
